import * as React from 'react'
import {
    Routes,
    Route,
    useLocation
    // Link,
    // NavLink
} from 'react-router-dom'
import { Layout } from './components/Site'

// import { Container, Navbar, Nav } from 'react-bootstrap'
// import Home from './pages/home'
// import About from './pages/about'
// import Contact from './pages/contact'

// {({ match }) => (

// )}

const Home = React.lazy(() => import('./pages/Home'))
const Error404 = React.lazy(() => import('./pages/Error404'))
const NaiveGex = React.lazy(() => import('./pages/NaiveGex/NaiveGex'))
const Disclaimer = React.lazy(() => import('./pages/Disclaimer'))
const GexMovingAverage = React.lazy(() =>
    import('./pages/GexMovingAverage/GexMovingAverage')
)

const RouteElement = ({ children, ...props }) => {
    return (
        <React.Suspense fallback={<>...</>}>
            <div className="page">{children}</div>
        </React.Suspense>
    )
}

export default function App() {
    const location = useLocation()

    return (
        <Routes location={location}>
            <Route path="/" element={<Layout />}>
                <Route
                    index
                    element={
                        <RouteElement>
                            <Home />
                        </RouteElement>
                    }
                />
                <Route
                    path="/naive-gex/"
                    element={
                        <RouteElement>
                            <NaiveGex />
                        </RouteElement>
                    }
                />
                <Route
                    path="/:id"
                    element={
                        <RouteElement>
                            <NaiveGex />
                        </RouteElement>
                    }
                />
                <Route
                    path="/21-dma-gex/"
                    element={
                        <RouteElement>
                            <GexMovingAverage />
                        </RouteElement>
                    }
                />
                <Route
                    path="/gex/"
                    element={
                        <RouteElement>
                            <GexMovingAverage />
                        </RouteElement>
                    }
                />
                <Route
                    path="/disclaimer/"
                    element={
                        <RouteElement>
                            <Disclaimer />
                        </RouteElement>
                    }
                />

                <Route
                    path="*"
                    element={
                        <RouteElement>
                            <Error404 />
                        </RouteElement>
                    }
                />
            </Route>
        </Routes>
    )
}
