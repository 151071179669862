import * as React from 'react'
import { Outlet, Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
//import { TransitionGroup, CSSTransition } from 'react-transition-group'
//import { useTransition, animated } from 'react-spring'

import { Helmet } from 'react-helmet'
import { Logo } from './Logo'
// <Navbar bg="light">
//     <Nav className="mx-auto">
//         {routes.map((route) => (
//             <Nav.Link
//                 key={route.path}
//                 as={NavLink}
//                 to={route.path}
//                 activeClassName="active"
//                 exact
//             >
//                 {route.name}
//             </Nav.Link>
//         ))}
//     </Nav>
// </Navbar>

const Main = React.forwardRef(({ children }, ref) => {
    //console.log(props)
    //return <main ref={props.nodeRef}>{children}</main>
    return <main ref={ref}>{children}</main>
})

function Layout() {
    //const location = useLocation()
    const nodeRef = React.useRef(null)
    //const [transitionName, setTransitionName] = React.useState('next')
    return (
        <Container className="App">
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                <title>SPYvsGME</title>
                <link rel="canonical" href="https://spyvsgme.com" />
            </Helmet>

            <nav>
                <Logo />
                <Link to="/naive-gex/">Naive Gex</Link>
                <Link to="/21-dma-gex/">21-dma Gex</Link>
            </nav>

            <Main ref={nodeRef}>
                <Outlet />
            </Main>
        </Container>
    )
}

// <TransitionGroup>
//     <CSSTransition
//         key={location.pathname}
//         nodeRef={nodeRef}
//         classNames={transitionName}
//         timeout={3000}
//     >
//     </CSSTransition>
// </TransitionGroup>

export { Layout }
